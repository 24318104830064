body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

textarea {
  width: -moz-available !important; /* For Gecko-based browsers */
  width: -webkit-fill-available; /* For WebKit-based browsers */
  width: stretch; /* Standard property, works in modern browsers */
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  background: none;
}

textarea:focus-visible {
  border-bottom-color: #52aebd !important;
}

ul.MuiPagination-ul {
  justify-content: center;
}

#collabora-online-viewer {
  width: 98%;
  height: 98%;
  position: absolute;
  flex-grow: 1;
}
#collabora-online-viewer-fs {
  width: 98%;
  height: 98%;
  position: absolute;
  flex-grow: 1;
}
#docs-online-viewer {
  width: 100%;
  height: 100%;
  position: absolute;
  flex-grow: 1;
  border-width: 0px;
}

ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.dark-overlay {
  background-color: rgba(102, 51, 153, 0.8);
  height: calc(100vh - 71px);
  /* max-height: 600px; */
}

.MuiDrawer-docked {
  width: 0 !important;
}

SVG {
  width: -webkit-fill-available !important;
}

.red-row {
  color: red;
}

.textareaWhite {
  background-color: #f6f7ff;
  margin: 16px 0 16px 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.youtubeVideo {
  /* margin-top: 12px; */
  width: 50vw;
  height: 55vh;
  position: relative;
  flex-grow: 1;
}

.fade-in {
  opacity: 0;
  animation: fadeInAnimation 2s forwards;
  transition: text-shadow 1s ease-in-out;
}

@keyframes fadeInAnimation {
  to {
    opacity: 1;
  }
}

.fade-in:hover {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}