/* basic positioning */
.legend {
  list-style: none;
}
.legend li {
  float: left;
  margin-right: 10px;
}
.legend span {
  border: 1px solid #ccc;
  float: left;
  width: 17px;
  height: 15px;
  margin: 5px;
}
